export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const USER_LOAD = "USER_LOAD";
export const LOG_OUT = "LOG_OUT";
export const GETPLAYERS_SUCCESS = "PLAYER_SUCCESS";
export const CHANGE_CURRENT_PAGE = "CHANGE_CURRENT_PAGE";
export const BOOKING_SUCCESS = "BOOKING_SUCCESS";
export const SET_BOOKING_DATE = "SET_BOOKING_DATE";
export const GET_BOOKING_DATA = "GET_BOOKING_DATA";
export const GET_USERS = "GET_USERS";
export const GET_CHATCONTENTS = "GET_CHATCONTENTS";
export const SET_SELECTEDUSERNAME = "SET_SELECTEDUSERNAME";
export const SAVE_CHATCONTENT = "SAVE_CHATCONTENT";
export const UPDATE_SEARCHINGPLAYER = "UPDATE_SEARCHINGPLAYER";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const GET_COMMENT = "GET_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const GET_PLAYERSCHEDULE = "GET_PLAYERSCHEDULE";
export const UPDATE_COURT_STATUS = "UPDATE_COURT_STATUS";
export const GET_COVEREDCALLPREMIUM = "GET_COVEREDCALLPREMIUM";
export const SYMBOL_NOT_FOUND_ERROR = "SYMBOL_NOT_FOUND_ERROR";
export const OPTIONS_NOT_FOUND_ERROR = "OPTIONS_NOT_FOUND_ERROR";
export const SERVICE_UNAVAILABLE_ERROR = "SERVICE_UNAVAILABLE_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";
export const GET_EXPIRATIONS = "GET_EXPIRATIONS";
